<template>
  <div class="allInOne">
    <div class="banner" style="font-size: 0px" ref="wrappers" v-if="orderData">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in orderData.infoImageUrls" :key="index">
          <!-- <img :src="item" alt="" class="place-bottom-img" :key="index" /> -->
          <picture>
            <source type="image/webp" :srcset="item.imageUrlWebp" class="place-bottom-img"/>
            <img :src="item.imageUrl" alt="" class="place-bottom-img"/>
          </picture>
        </van-swipe-item>
      </van-swipe>
      <van-notice-bar left-icon="volume-o" :scrollable="false" class="notice" v-if="orderData.rollInfoFlag">
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in listContent" :key="index" class="van-item">
            {{ item.receiverName }} {{ item.receiverPhone }}
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar>
    </div>
    <!-- <ul class="productListWrapper" :style="{ backgroundColor: orderData.formColour }"> -->
    <ul class="productListWrapper" v-if="dataList.length > 0">
      <li @click="checkShowType(item)" class="list" v-for="(item, index) in dataList" :key="index">
        <img src="@/assets/selectCheck.png" alt="" class="checkImg" v-show="item.productCode === productId">
        <!-- <img :src="item.imageUrl" alt="" class="contentImg"> -->
        <picture>
          <source type="image/webp" :srcset="item.imageUrlWebp" class="contentImg"/>
          <img :src="item.imageUrl" alt="" class="contentImg"/>
        </picture>
      </li>
    </ul>
    <placeOrder v-if="showType == 1" :componentBoolean="true" @ok="setOrderData" :productId="productId" ref="place"></placeOrder>
    <phoneOrder v-if="showType == 2" :componentBoolean="true" @ok="setOrderData" :productId="productId" ref="phone"></phoneOrder>
  </div>
</template>

<script>
  import placeOrder from '../placeOrder/index.vue';
  import phoneOrder from '../phoneOrderTwo/index.vue';
  import { getWebPageProductList } from '@/api/api';
  export default {
    components: {
      placeOrder,
      phoneOrder
    },
    data() {
      return {
        showType: null,
        orderData: null,
        productId: null,
        dataList: [],
      }
    },
    created () {
      // this.$store.commit('setDyClickId', this.$route.query.clickid)
      this.$store.commit("setClickId", window.location.href);
      console.log('***********dy', this.$store.state.clickid)
      this.productId = this.$route.params.productId;
      this._getWebPageProductList();
    },
    methods: {
      _getWebPageProductList() {
        getWebPageProductList({id: this.$route.params.id}).then(res => {
          this.dataList = res.result
          this.showType = res.result[0].pageType
          this.productId = res.result[0].productCode
        })
      },
      checkShowType (argument) {
        this.showType = null;
        this.productId = argument.productCode
        this.showType = argument.pageType
        if (this.showType === 1) {
          setTimeout(() => {
            this.$refs.place._getData(this.$route.params);
          }, 20);
        } else {
          setTimeout(() => {
            this.$refs.phone.clearValue();
            this.$refs.phone._getData(this.$route.params);
          }, 20);
        }
      },
      setOrderData (data) {
        this.orderData = data
      }
    }
  }
</script>

<style scoped lang="less">
  .allInOne {
    min-height: 100%;
    .productListWrapper {
      padding: 10px 18px;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      overflow-x: scroll;
      .list {
        flex-grow: 0;
        flex-shrink: 0;
        margin-right: 15px;
        width: 48%;
        background-color: red;
        height: 99px;
        border-radius: 24px;
        position: relative;
        .contentImg {
          width: 100%;
          height: 100%;
          border-radius: 24px;
        }
        .checkImg {
          position: absolute;
          left: 0;
          top: 0;
          z-index: 1000;
          width: 35%;
          height: 50%;
        }
        // box-shadow: -15px 0px 27px 0px #FFCC97;
      }
    }
    .banner {
      // height 375px
      position: relative;
      .banner-img {
        width: 100%;
        height: 100%;
      }
      .place-bottom-img {
        width: 100%;
        // height: 100%;
      }
      .notice {
        position: absolute;
        top: 10%;
        left: 2%;
        width: 45%;
        height: 30px;
        border-radius: 4px;
        background-color: rgba(0, 0, 0, 0.5);
        .notice-swipe {
          height: 30px;
          line-height: 30px;
          color: #fff;
        }
      }
    }
  }
</style>