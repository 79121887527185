<template>
  <div class="place-an-order" >
    <div class="kfWrapper" v-if="orderData.kefuFlag" @click="goKf">
      <img src="../../assets/kf.png" alt="" />
    </div>
    <div class="banner" style="font-size: 0px" ref="wrappers" v-if="!componentBoolean">
      <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="(item, index) in orderData.infoImageUrls" :key="index">
          <!-- <img :src="item" alt="" class="place-bottom-img" :key="index" /> -->
          <picture>
            <source type="image/webp" :srcset="item.imageUrlWebp" class="place-bottom-img"/>
            <!-- <source type="image/png" :srcset="item.imageUrl" class="place-bottom-img"/> -->
            <img :src="item.imageUrl" alt="" class="place-bottom-img"/>
          </picture>
        </van-swipe-item>
      </van-swipe>
      <!-- <van-notice-bar
        left-icon="volume-o"
        :scrollable="false"
        class="notice"
        v-if="orderData.rollInfoFlag"
      >
        <van-swipe vertical class="notice-swipe" :autoplay="3000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in listContent" :key="index" class="van-item">
            {{ item.receiverName }} {{ item.receiverPhone }}
          </van-swipe-item>
        </van-swipe>
      </van-notice-bar> -->
    </div>
    <div
      class="order-wrapper"
      :style="{ backgroundColor: orderData.formColour }"
      id="btn"
      ref="btn"
      v-if="orderData"
    >
      <div class="form-header">
        <div class="form-name text-left" 
          v-show="orderData.formName" 
          :style="{ textAlign: orderData.titleCenter == true ? 'center' : 'left', color: orderData.titleColour }"
        >
          {{ orderData.formName }}
        </div>
        <div style="height: 40px; background-color: #fff; padding: 0 20px;" v-if="orderData.rollInfoFlag">
          <div class="scrollContainer">
            <div class="iconLeft">
              <van-icon name="volume-o"/>
            </div>
            <div class="scrollRight">
              <van-swipe 
                :autoplay="2000" 
                :show-indicators="false" 
                :touchable="false" 
                :vertical="orderData.rollInfoStyle == '1' ? true : false" 
                style=" height: 40px;"
              >
                <van-swipe-item class="content" v-for="(item,index) in listContent" :key="index">
                  <p>{{item.receiverName}}{{item.receiverPhone}}刚刚下单</p>
                </van-swipe-item>
              </van-swipe>
            </div>
          </div>
        </div>
        <div class="form-sales text-right" v-show="orderData.salesVolumeFlag">
          销量：{{ orderData.salesVolume }}
        </div>
      </div>
      <div class="order">
        <van-form>
          <template v-if="!idNumberPictureFlag">
            <van-field
              v-model="order.receiverName"
              center
              name="您的姓名"
              :label="(orderData.idNumberFlag &&
                  this.collapseForm &&
                  order.receiverMobile.length >= 11) ||
                (orderData.idNumberFlag && !this.collapseForm) ? '身份证姓名：' : '您的姓名：'"
              placeholder="请输入姓名"
              class="field-top"
            />
            <van-field
              v-if="
                (orderData.idNumberFlag &&
                  this.collapseForm &&
                  order.receiverMobile.length >= 11) ||
                (orderData.idNumberFlag && !this.collapseForm)
              "
              v-model="order.receiverIdCard"
              center
              maxlength="18"
              name="身份证号"
              label="身份证号："
              placeholder="请输入身份证号码"
              class="field-top"
            >
            </van-field>
            <van-field
              v-model="order.receiverMobile"
              center
              name="手机号码"
              label="手机号码："
              type="number"
              placeholder="请输入手机号"
              maxlength="11"
            />
            <van-field
              v-if="(this.collapseForm && order.receiverMobile.length >= 11) || !this.collapseForm"
              v-model="order.smsCode"
              center
              name="验证码"
              label="验证码"
              type="number"
              placeholder="请输入验证码"
              maxlength="4"
            >
              <template #button>
                <van-button
                  style="width: 7em"
                  @click="_sendSmsCode"
                  :disabled="authTime !== '获取验证码'"
                  size="mini"
                  type="primary"
                >
                  {{ authTime }}
                </van-button>
              </template>
            </van-field>
            <van-field
              v-if="orderData.needIccid"
              v-model="order.iccid"
              center
              name="iccid"
              label="ICCID："
              type="number"
              placeholder="请输入iccid"
              maxlength="19"
            />
          </template>
          <template v-else>
            <van-field
              v-model="order.receiverMobile"
              center
              name="手机号码"
              label="手机号码："
              type="number"
              placeholder="请输入手机号"
              maxlength="11"
            />
            <van-field
              v-if="(this.collapseForm && order.receiverMobile.length >= 11) || !this.collapseForm"
              v-model="order.smsCode"
              center
              name="验证码"
              label="验证码"
              type="number"
              placeholder="请输入验证码"
              maxlength="6"
            >
              <template #button>
                <van-button
                  style="width: 7em"
                  @click="_sendSmsCode"
                  :disabled="authTime !== '获取验证码'"
                  size="mini"
                  type="primary"
                >
                  {{ authTime }}
                </van-button>
              </template>
            </van-field>
            <van-field
              v-if="orderData.needIccid"
              v-model="order.iccid"
              center
              name="iccid"
              label="ICCID："
              type="number"
              placeholder="请输入iccid"
              maxlength="19"
            />
          </template>
          <van-field
            v-if="(this.collapseForm && order.receiverMobile.length >= 11) || !this.collapseForm"
            center
            readonly
            clickable
            name="area"
            :value="value"
            label="所在地区："
            placeholder="点击选择省市区"
            @click="showArea = true"
          >
            <template #button>
              <div class="area-button-box">
                <van-icon @click.stop="clearValue" v-show="value !== ''" name="close" />
              </div>
            </template>
          </van-field>
          <van-popup v-model="showArea" position="bottom">
            <van-area :area-list="areaList" @confirm="onConfirm" @change="handleChange" @cancel="showArea = false" />
          </van-popup>
          <van-field
            v-if="(this.collapseForm && order.receiverMobile.length >= 11) || !this.collapseForm"
            v-model="order.receiverAddress"
            type="textarea"
            name="详细地址"
            label="详细地址："
            placeholder="如街道、小区门牌等详细地址"
          />
          <div class="field-imitate" v-if="idNumberPictureFlag && thirdPartyProductType !== 5">
            <div class="field-label">
              上传身份证<span class="link" @click="showIdUploadExample">(查看示例)</span>：
            </div>
            <div class="field-content">
              <ul>
                <li
                  v-if="identityCardImageTypes && Object.keys(identityCardImageTypes).includes('1')"
                >
                  <div>
                    <van-uploader
                      :max-count="1"
                      :after-read="uploadBackSide"
                      v-model="identityVerify.obverse"
                      @delete="onFrontImgDel"
                    />
                  </div>
                  <div class="title">人像面</div>
                </li>
                <li
                  v-if="identityCardImageTypes && Object.keys(identityCardImageTypes).includes('2')"
                >
                  <div>
                    <van-uploader
                      :max-count="1"
                      :after-read="uploadReverseSide"
                      v-model="identityVerify.reverse"
                      @delete="onBackImgDel"
                    />
                  </div>
                  <div class="title">国徽面</div>
                </li>
                <li
                  v-if="
                    identityCardImageTypes &&
                    Object.keys(identityCardImageTypes).includes('3') &&
                    order.idCardFrontUrl
                  "
                >
                  <div>
                    <van-uploader
                      @delete="onPersonImgDel"
                      :max-count="1"
                      :after-read="uploadPersonImg"
                      v-model="identityVerify.person"
                    />
                  </div>
                  <div class="title">半身照</div>
                </li>
              </ul>
            </div>
          </div>

          <div class="field-imitate" v-if="idNumberPictureFlag && thirdPartyProductType === 5">
            <div class="field-label">
              上传身份证<span class="link" @click="showIdUploadExample">(查看示例)</span>：
            </div>
            <div class="field-content">
              <ul>
                <li>
                  <div>
                    <van-uploader
                      :max-count="1"
                      :after-read="uploadBackSideTibet"
                      v-model="identityVerify.obverse"
                      @delete="onFrontImgDelTibet"
                    />
                  </div>
                  <div class="title">人像面</div>
                </li>
                <li>
                  <div>
                    <van-uploader
                      :max-count="1"
                      :after-read="uploadReverseSideTibet"
                      v-model="identityVerify.reverse"
                      @delete="onBackImgDelTibet"
                    />
                  </div>
                  <div class="title">国徽面</div>
                </li>
                <li>
                  <div>
                    <van-uploader
                      :after-read="uploadPersonImgTibet"
                      @delete="onPersonImgDelTibet"
                      :max-count="1"
                      v-model="identityVerify.person"
                    />
                  </div>
                  <div class="title">正面免冠照</div>
                </li>
              </ul>
            </div>
          </div>

          <template v-if="idNumberPictureFlag">
            <van-field
              :disabled="personInfoDisabled && thirdPartyProductType !== 5"
              v-model="order.receiverName"
              center
              name="您的姓名"
              :label="orderData.idNumberFlag ? '身份证姓名：' : '您的姓名：'"
              placeholder="请输入姓名"
              class="field-top"
            />
            <van-field
              v-if="orderData.idNumberFlag"
              :disabled="personInfoDisabled && thirdPartyProductType !== 5"
              v-model="order.receiverIdCard"
              center
              maxlength="18"
              name="身份证号"
              label="身份证号："
              placeholder="请输入身份证号码"
              class="field-top"
            >
            </van-field>
          </template>

          <van-field
            v-if="orderData.showFlag"
            readonly
            clickable
            name="意向套餐"
            :value="purposePackage"
            label="意向套餐"
            placeholder="请选择意向套餐"
            @click="showPicker = true"
          />
          <van-popup v-model="showPicker" position="bottom">
            <van-picker
              show-toolbar
              :columns="purposePackageColumns"
              @confirm="purposePackageConfirm"
              @cancel="showPicker = false"
            />
          </van-popup>
        </van-form>

        <van-checkbox
          v-model="checked"
          icon-size="14px"
          shape="square"
          class="search-check search-agreement"
          checked-color="#ee0a24"
        >
          我已阅读并同意
          <span @click.stop="agreementClick">《入网许可协议》</span>
          <span @click.stop="noticeClick">《个人信息收集证明》</span>

          <span @click.stop="personalInformationClick">《个人信息保护政策》</span>
          <span @click.stop="letterOfAgreementClick">《单独同意书》</span>
          <span @click.stop="policyClick">《隐私政策》</span>
        </van-checkbox>
        <div class="btn-wrapper">
          <van-button
            type="danger"
            class="place-btn"
            @click="placeOrder"
            :disabled="forbidden || pageId === '271283' || pageId === '330236'"
            :style="{ backgroundColor: orderData.buttonColour }"
          >
            {{ buttonName ? buttonName : "提交申请" }}
          </van-button>
        </div>
      </div>
    </div>
    <!-- 底部图片 -->
    <div
      style="font-size: 0px"
      v-if="orderData.detailDrawingImgUrls && orderData.detailDrawingImgUrls.length"
    >
      <!-- <img
        :src="item"
        alt=""
        class="place-bottom-img"
        v-for="(item, index) in orderData.detailDrawingImgUrls"
        :key="index"
      /> -->
      <picture v-for="(item, index) in orderData.detailDrawingImgUrls" :key="index">
        <source type="image/webp" :srcset="item.imageUrlWebp" class="place-bottom-img"/>
        <img :src="item.imageUrl" alt="" class="place-bottom-img"/>
      </picture>
    </div>
    <!-- 协议 -->
    <AgreementPopup ref="agreementPopup" />
    <!-- 协议二 -->
    <NoticePopup ref="noticePopup" />
    <policyPopup ref="policyPopup" />
    <personalInformationPopup ref="personalInformationPopup" />
    <letterOfAgreementPopup ref="letterOfAgreementPopup" />
    <!-- 上传身份证实例 -->
    <IdCardUploadPopupExample ref="idCardUploadPopupExample" :types="identityCardImageTypes" />
    <!-- 西藏卡实名认证 -->
    <XZCardRealName ref="xzCardRealName" @showIdUploadExample="showIdUploadExample" />

    <van-popup
      v-model="fixedFormShow"
      style="margin-bottom: 50px"
      position="bottom"
      v-if="fixedBtnShow"
    >
      <div class="order-wrapper" :style="{ backgroundColor: orderData.formColour }">
        <div class="order">
          <van-form>
            <template v-if="!idNumberPictureFlag">
              <van-field
                v-model="order.receiverName"
                center
                name="您的姓名"
                :label="orderData.idNumberFlag ? '身份证姓名：' : '您的姓名：'"
                placeholder="请输入姓名"
                class="field-top"
              />
              <van-field
                v-if="orderData.idNumberFlag"
                v-model="order.receiverIdCard"
                center
                maxlength="18"
                name="身份证号"
                label="身份证号："
                placeholder="请输入身份证号码"
                class="field-top"
              >
              </van-field>
            </template>
            <van-field
              v-model="order.receiverMobile"
              center
              name="手机号码"
              label="手机号码："
              type="number"
              placeholder="请输入手机号"
              maxlength="11"
            />
            <van-field
              v-model="order.smsCode"
              center
              name="验证码"
              label="验证码"
              type="number"
              placeholder="请输入验证码"
              maxlength="6"
            >
              <template #button>
                <van-button
                  style="width: 7em"
                  @click="_sendSmsCode"
                  :disabled="authTime !== '获取验证码'"
                  size="mini"
                  type="primary"
                >
                  {{ authTime }}
                </van-button>
              </template>
            </van-field>
            <van-field
              center
              readonly
              clickable
              name="area"
              :value="value"
              label="所在地区："
              placeholder="点击选择省市区"
              @click="showArea = true"
            >
              <template #button>
                <div class="area-button-box">
                  <van-icon @click.stop="clearValue" v-show="value !== ''" name="close" />
                </div>
              </template>
            </van-field>
            <van-popup v-model="showArea" position="bottom">
              <van-area :area-list="areaList" @confirm="onConfirm" @change="handleChange" @cancel="showArea = false" />
            </van-popup>
            <van-field
              v-model="order.receiverAddress"
              type="textarea"
              name="详细地址"
              label="详细地址："
              placeholder="如街道、小区门牌等详细地址"
            />
            <div class="field-imitate" v-if="idNumberPictureFlag && thirdPartyProductType !== 5">
              <div class="field-label">
                上传身份证<span class="link" @click="showIdUploadExample">(查看示例)</span>：
              </div>
              <div class="field-content">
                <ul>
                  <li
                    v-if="
                      identityCardImageTypes && Object.keys(identityCardImageTypes).includes('1')
                    "
                  >
                    <div>
                      <van-uploader
                        :max-count="1"
                        :after-read="uploadBackSide"
                        v-model="identityVerify.obverse"
                        @delete="onFrontImgDel"
                      />
                    </div>
                    <div class="title">人像面</div>
                  </li>
                  <li
                    v-if="
                      identityCardImageTypes && Object.keys(identityCardImageTypes).includes('2')
                    "
                  >
                    <div>
                      <van-uploader
                        :max-count="1"
                        :after-read="uploadReverseSide"
                        v-model="identityVerify.reverse"
                        @delete="onBackImgDel"
                      />
                    </div>
                    <div class="title">国徽面</div>
                  </li>
                  <li
                    v-if="
                      identityCardImageTypes &&
                      Object.keys(identityCardImageTypes).includes('3') &&
                      order.idCardFrontUrl
                    "
                  >
                    <div>
                      <van-uploader
                        @delete="onPersonImgDel"
                        :max-count="1"
                        :after-read="uploadPersonImg"
                        v-model="identityVerify.person"
                      />
                    </div>
                    <div class="title">半身照</div>
                  </li>
                </ul>
              </div>
            </div>

            <template v-if="idNumberPictureFlag">
              <van-field
                :disabled="personInfoDisabled && thirdPartyProductType !== 5"
                v-model="order.receiverName"
                center
                name="您的姓名"
                :label="orderData.idNumberFlag ? '身份证姓名：' : '您的姓名：'"
                placeholder="请输入姓名"
                class="field-top"
              />
              <van-field
                v-if="orderData.idNumberFlag"
                :disabled="personInfoDisabled && thirdPartyProductType !== 5"
                v-model="order.receiverIdCard"
                center
                maxlength="18"
                name="身份证号"
                label="身份证号："
                placeholder="请输入身份证号码"
                class="field-top"
              >
              </van-field>
              <van-field
                v-if="orderData.needIccid"
                v-model="order.iccid"
                center
                name="iccid"
                label="ICCID："
                type="number"
                placeholder="请输入iccid"
                maxlength="19"
              />
            </template>

            <van-field
              v-if="orderData.showFlag"
              readonly
              clickable
              name="意向套餐"
              :value="purposePackage"
              label="意向套餐"
              placeholder="请选择意向套餐"
              @click="showPicker = true"
            />
            <van-popup v-model="showPicker" position="bottom">
              <van-picker
                show-toolbar
                :columns="purposePackageColumns"
                @confirm="purposePackageConfirm"
                @cancel="showPicker = false"
              />
            </van-popup>
          </van-form>

          <van-checkbox
            v-model="checked"
            icon-size="14px"
            shape="square"
            class="search-check search-agreement"
            checked-color="#ee0a24"
          >
            我已阅读并同意
            <span @click.stop="agreementClick">《入网许可协议》</span>
            <span @click.stop="noticeClick">《个人信息收集证明》</span>

            <span @click.stop="personalInformationClick">《个人信息保护政策》</span>
            <span @click.stop="letterOfAgreementClick">《单独同意书》</span>
            <span @click.stop="policyClick">《隐私政策》</span>
          </van-checkbox>
          <div class="btn-wrapper">
            <van-button
              type="danger"
              class="place-btn"
              @click="placeOrder"
              :disabled="forbidden"
              :style="{ backgroundColor: orderData.buttonColour }"
            >
              提交申请
            </van-button>
          </div>
        </div>
      </div>
    </van-popup>

    <van-popup
      v-model="submittedRedirectPopupVisible"
      style="background-color: transparent; width: 100%"
      :close-on-click-overlay="false"
    >
      <div class="redirect-img">
        <van-icon @click="hideRedirectPopup" class="redirect-img-close" name="cross" :size="22" />
        <img :src="submittedRedirectImgUrl" />
      </div>
    </van-popup>
    <div class="fixed-btn" v-show="fixedBtnShow">
      <img
        @click="toTop"
        :src="require('@/assets/toTop.png')"
        style="width: 20px; height: 20px; padding: 0 15px"
      />
      <van-button
        type="danger"
        @click="fixedBtnClick"
        style="width: 100%; height: 50px; font-size: 17px; font-weight: bold"
      >
        提交申请
      </van-button>
    </div>
    <div class="bottom-fixed-wrapper" v-if="orderData.appletJumpUrl">
      <div></div>
      <van-button type="danger" @click="addFriends">去微信加好友</van-button>
    </div>
    <van-popup
      v-model="alertVisible"
      style="background-color: transparent; width: 100%"
      :close-on-click-overlay="false"
    >
      <div class="alertPopup">
        <div class="alertText">{{ alertVisibleText }}</div>
        <van-button type="danger" class="btn" @click="closeAlertPopup">确认</van-button>
      </div>
    </van-popup>
    <van-popup
      v-model="payVisible"
      :close-on-click-overlay="false"
      position="bottom"
      round
      closeable
      class="payAlert"
    >
      <div class="title">支付金额</div>
      <div class="money">{{ this.orderData.sellingPrice }}<span class="unit">元</span></div>
      <div class="alertPayPopup">
        <div class="payRadioWrapper">
          <van-radio-group v-model="checkedPay">
            <van-radio name="1" label-position="left">
              <img src="@/assets/wxpay.png" alt="" class="pay-img"/>微信支付
            </van-radio>
            <div style="width: 90%; height: 1px; margin: 15px auto; background-color: #ebebeb"></div>
            <van-radio name="2" label-position="left">
              <img src="@/assets/alipay.png" alt="" class="pay-img"/>支付宝支付
            </van-radio>
          </van-radio-group>
        </div>
      </div>
      <div class="payBtnWrapper">
        <van-button type="danger" class="btn" :disabled="forbidden" @click="placeOrderTwo">确认订单</van-button>
      </div>
    </van-popup>
    <!-- <div class="fixed-btn" v-show="fixedBtnShow">
      <van-button
        type="danger"
        class="place-btn"
        :style="{ backgroundColor: orderData.formButtonBackground }"
        @click="goTable"
        >立即选购</van-button
      >
    </div> -->
    <van-popup v-model:show="showDelist" round style="padding: 34px;" :close-on-click-overlay="false">该商品已下架</van-popup>
  </div>

</template>

<script>
import {
  getWebPage,
  getArea,
  order,
  orderTibet,
  // postPayOrder,
  // getAreaList,
  getNumberCode,
  getUserNumberCode,
  postBottomPayOrder,
  // getRolling,
  uploadIdCard,
  uploadIdCardFront,
  uploadIdCardBack,
  uploadIdCardPerson,
  getLocation,
  apiGetTransitFlag,
  getWebPageAllInfo,
} from "@/api/api";
import XZCardRealName from "./modules/XZCardRealName.vue";
import IdCardUploadPopupExample from "./modules/IdCardUploadPopupExample.vue";
import AgreementPopup from "./modules/AgreementPopup.vue";
import NoticePopup from "./modules/NoticePopup.vue";
import { checkIdCard } from "@/utils";
import Compressor from "compressorjs";
import store from "../../store";
import letterOfAgreementPopup from "./modules/letterOfAgreementPopup";
import personalInformationPopup from "./modules/personalInformationPopup";
import policyPopup from "./modules/policyPopup";
let timer = null; //定义自动跳转延时器
//监听浏览器关闭事件
const obj = {
  failJumpTreeFlag: false,  //判断是否跳转
  judgmentOrder: false   //判断是否下单
};
// 定义一个obj变量监听器
const handler = {
  set(target, key, value) {
    target[key] = value;
    return true;
  },
};
// 创建一个Proxy对象来监听obj变量的变化
const proxyObj = new Proxy(obj, handler);
// document.addEventListener('backbutton', function (e) {
//   if(obj.failJumpTreeFlag && !obj.judgmentOrder){
//     window.history.back()
//     skipInfo("home_leave")
//   }
// }, false);
//监听离开或关闭页面的方法
window.addEventListener("popstate", function (e) {
  if(obj.failJumpTreeFlag && !obj.judgmentOrder){
    window.history.back()
    skipInfo("home_leave")
  }
}, false);
//改方法添加了一个历史记录，路径为#，保证了不刷新页面，不直接后退。
function pushHistory() { 
  var state = {
    title: "title",
    url: "#"
  };
  window.history.pushState(state, "title", "#");
}
//添加跳转信息函数
function skipInfo(pointNum){
  // 初始化sdk
  let time = new Date().getTime()
  let random = Math.floor(Math.random() * (999999 - 1 + 1) + 1); // 生成范围在[1, 999999]内的随机整数
  let sessionId = time + '' + random;
  const sdk = recsdk.init({
    "sessionId": sessionId,
    "unionKey": "d465bad1c3254033aaf46fe37d875047",
  })
  // 调用获取推荐产品，获取成功后自动跳转连接
  sdk.getAd({
      isp: "ISP-CMCC",
      phoneNum: "",
      phoneProvince: "",
      ErrCode: "",
      errMsg: "",
      Media: "",
      pointNum,
  }, err => {
      // 获取失败的回调 - 可自行处理错误流程
      console.log('err==>', err)
  })
  clearTimeout(timer);
  timer = null;
}
const imgCompressor = function (file, maxWidth = 1000, maxHeight = 1000, quality = 0.8) {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality,
      maxWidth,
      maxHeight,
      success: (result) => {
        // console.log(result);
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    });
  });
};

export default {
  name: "placeAnOrder",
  props: {
    componentBoolean: {
      type: Boolean,
      default() {
        return false
      }
    },
    productId: {
      type: String || Number,
      default() {
        return null
      }
    },
  },
  data() {
    return {
      purposePackageColumns: [],
      showPicker: false,
      purposePackage: "",
      identityVerify: {
        obverse: [],
        reverse: [],
        person: [],
      },
      loading: null,
      radio: "1",
      showArea: false,
      value: "", //地区
      area: {
        receiverProvince: "",
        receiverCity: "",
        receiverCounty: "",
      },
      areaList: [], //城市列表数据
      listContent: [],
      // 表单数据
      order: {
        receiverName: "",
        receiverMobile: "",
        smsCode: "",
        receiverAddress: "",
        receiverIdCard: "",
        // CodeNumber: "",
        idCardFrontUrl: "",
        idCardBackUrl: "",
        idCardHandUrl: "",
        userIntentionPackageConfigId: "",
        iccid: "",
      },
      cityCode: "",
      orderData: "", //页面显示数据
      checked: false, //是否勾选协议
      // 短信
      authTime: "获取验证码",
      btnShow: true,
      // 协议
      agreementShow: false,
      noticeShow: false,
      showPopup: false,
      fixedBtnShow: false,
      fixedLength: null,
      transitionOpportune: -1,
      transitionCode: "",
      transitionType: 0,
      fixedFormShow: false,
      transitionJsFlag: false,
      submittedRedirectType: 0,
      thirdPartyProductType: 0,
      submittedRedirectImgUrl: "",
      submittedRedirectPopupVisible: false,
      idNumberPictureFlag: null,
      identityCardImageTypes: null,
      personInfoDisabled: false,
      buttonName: false,
      collapseForm: false,
      pageId: "",
      alertVisible: false,
      alertVisibleText: null,
      payVisible: false,
      checkedPay: '1',
      payStatus: false,
      t: null,    //提交防抖定时器
      forbidden: false,  //提交禁用标志 true：禁用，false：不禁用
      countyList: [], // 区列表
      showDelist: false, // 下架显示
    };
  },
  components: { IdCardUploadPopupExample, AgreementPopup, NoticePopup, XZCardRealName, letterOfAgreementPopup, personalInformationPopup, policyPopup },
  created() {
    this._getData(this.$route.params);
    this.pageId = this.$route.params.id;
    if(this.$route.params && (this.$route.params.id === '271283' || this.$route.params.id === '330236')){
      console.log('this.pageId', this.pageId);
      this.showDelist = true;
    }
    
    this.$store.commit("setPathName", window.location.pathname);
    // console.log('*************************', this.$route.params.id)
    // console.log('*************************', this.componentBoolean)
  },
  mounted() {
    // this.showXZCardRealName()
    // this.$refs.idCardUploadPopupExample.show()
  },
  destroyed() {},
  methods: {
    closeAlertPopup () {
      this.alertVisible = false;
    },
    purposePackageConfirm(val) {
      console.log("purposePackageConfirm", val);
      this.purposePackage = val.text;
      this.order.userIntentionPackageConfigId = val.key;
      this.showPicker = false;
    },
    fixedBtnClick() {
      if (this.fixedFormShow) {
        this.placeOrder();
      } else {
        this.fixedFormShow = true;
      }
    },
    toTop() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50;
        if (top <= 0) {
          document.body.scrollTop = document.documentElement.scrollTop = top = 0;
          clearInterval(timeTop);
        }
      }, 10);
    },
    addFriends() {
      window.location.href = this.orderData.appletJumpUrl;
    },
    clearValue() {
      this.value = "";
      this.area.receiverProvince = "";
      this.area.receiverCity = "";
      this.area.receiverCounty = "";
    },
    placeOrder () {
      // 零元
      console.log('12314546', this.payStatus)
      // console.log('************', this.thirdPartyProductType)
      if (this.payStatus && this.thirdPartyProductType !== 5) {
        this.payVisible = true
        return;
      } else {
        this.placeOrderTwo()
      }
    },
    // 提交表单进行防抖处理
    placeOrderTwo(){
      // console.log('this.t', this.t);
      if(this.t !== null){
        clearTimeout(this.t);
        this.t = null;
      };
      this.t = setTimeout(() => {
        if(!this.forbidden){
          this.submitInfo();
        }
      }, 500)
    },
    // 提交表单信息
    submitInfo() {
      console.log(this.order.smsCode);
      window.localStorage.setItem("yzm", this.order.smsCode);

      if (!this.idNumberPictureFlag && this.order.receiverName === "") {
        this.$toast("请输入姓名");
        return;
      }
      if (!this.idNumberPictureFlag && this.orderData.idNumberFlag) {
        if (this.order.receiverIdCard === "") {
          this.$toast("请输入身份证号码");
          return;
        }
        const checkCard = checkIdCard(this.order.receiverIdCard);
        if (!checkCard.pass) {
          this.$toast("身份证号格式错误");
          return;
        }
      }
      if (this.order.receiverMobile === "") {
        this.$toast("请输入手机号");
        return;
      }
      if (this.order.smsCode === "") {
        this.$toast("请输入验证码");

        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.order.receiverMobile)) {
        this.$toast("请输入正确的11位手机号");
        return;
      }
      if (
        this.area.receiverProvince === "" &&
        this.area.receiverCity === "" &&
        this.area.receiverCounty === ""
      ) {
        this.$toast("请选择所在地区（省/市/区）");
        return;
      }
      if (this.order.receiverAddress === "") {
        this.$toast("请输入详细地址");
        return;
      }

      if (
        this.idNumberPictureFlag &&
        this.thirdPartyProductType !== 5 &&
        this.identityCardImageTypes
      ) {
        if (
          Object.keys(this.identityCardImageTypes).includes("1") &&
          this.order.idCardFrontUrl === ""
        ) {
          this.$toast("请上传身份证人像面照片");
          return;
        }
        if (
          Object.keys(this.identityCardImageTypes).includes("2") &&
          this.order.idCardBackUrl === ""
        ) {
          this.$toast("请上传身份证国徽面照片");
          return;
        }
        if (
          Object.keys(this.identityCardImageTypes).includes("3") &&
          this.order.idCardHandUrl === ""
        ) {
          this.$toast("请上传手持身份证半身照");
          return;
        }
      }

      if (this.idNumberPictureFlag && this.order.receiverName === "") {
        this.$toast("请输入姓名");
        return;
      }
      if (this.idNumberPictureFlag && this.orderData.idNumberFlag) {
        if (this.order.receiverIdCard === "") {
          this.$toast("请输入身份证号码");
          return;
        }
        const checkCard = checkIdCard(this.order.receiverIdCard);
        if (!checkCard.pass) {
          this.$toast("身份证号格式错误");
          return;
        }
      }
      if (!this.checked) {
        this.$toast("请阅读并同意相关协议");
        return;
      }
      // 是否显示ICCID
      if (this.orderData.needIccid) {
        if (!this.order.iccid || this.order.iccid.length < 19) {
          return this.$toast("请输入正确的ICCID");
        }
      }
      this.forbidden = true;
      const params = {
        // cardId: "",
        // cardNo: "",
        iccid: this.order.iccid,
        productId: this.orderData.productId,
        // receiverName
        // receiverMobile
        // receiverAddress
        // receiverIdCard: "",
        receiverName: this.order.receiverName,
        receiverMobile: this.order.receiverMobile,
        smsCode: this.order.smsCode,
        receiverAddress: this.order.receiverAddress,
        receiverProvince: this.area.receiverProvince,
        receiverCity: this.area.receiverCity,
        receiverCounty: this.area.receiverCounty,
        webPageId: this.$route.params.id,
        idCardBackUrl: this.order.idCardBackUrl,
        idCardFrontUrl: this.order.idCardFrontUrl,
        idCardHandUrl: this.order.idCardHandUrl,
        userIntentionPackageConfigId: this.order.userIntentionPackageConfigId,
        // receiverProvince
        // receiverCity
        // receiverCounty
      };
      // console.log(params);
      // return;
      if (this.orderData.idNumberFlag) {
        Object.assign(params, { receiverIdCard: this.order.receiverIdCard });
      }
      if (this.loading !== null) {
        this.loading.clear();
        this.loading = null;
      }
      this.loading = this.$toast.loading({
        forbidClick: true,
        loadingType: "spinner",
        duration: 0,
      });
      this.$store.commit("setClickId", window.location.href);

      let api = order;

      if (this.thirdPartyProductType === 5) {
        // this.showXZCardRealName(res.result.orderNo);
        api = orderTibet;
      }

      if (this.payStatus && this.thirdPartyProductType !== 5) {
        params.payType = this.checkedPay;
        params.orderSource = 'H5'
      }
      // console.log('params======>', params);
      //确认订单
      api(params).then((res) => {
        console.log('确认订单的res===>', res);
        //两秒后调用函数跳转发财树
        if(proxyObj.failJumpTreeFlag){
          timer = setTimeout(() => {
            //下单失败判断
            if(res.code == 201 && !obj.judgmentOrder && res.message.indexOf("验证码") == -1){
              window.history.back();
              skipInfo("order_fail");
              proxyObj.judgmentOrder = true;
            }
          },2000)
        }        
        if (res && res.code === 200) {
          if (this.payStatus) {
            let u = navigator.userAgent;
            let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1;
            if (isAndroid) {
              if(res.result.payInfo){
                window.open(res.result.payInfo.body);
              }
            } else {
              if(res.result.payInfo){
                window.location.href = res.result.payInfo.body
              }
            }
          }
          this.jsTransform(2).then(() => {
            if (this.submittedRedirectType === 1) {
              this.clearFormData();
              this.showRedirectPopup();
            } else {
              setTimeout(() => {
                this.$router.push(`/result/${res.result.orderNo}?status=${this.payStatus}`);
              }, 2500);
            }
          });
        } else {
          this.forbidden = false;
          this.alertVisible = true
          this.alertVisibleText = res.message
          // this.$toast(res.message);
        }
        if (this.loading === null) return;
        this.loading.clear();
        this.loading = null;
        // this.$toast(res.message);
      }).catch(err => {
        if (this.loading === null) return;
        this.forbidden = false;
        this.loading.clear();
        this.loading = null;
      });
    },
    clearFormData() {
      this.order.receiverName = "";
      this.order.receiverMobile = "";
      this.order.smsCode = "";
      this.order.receiverAddress = "";
      this.order.receiverIdCard = "";
      this.clearValue();
    },
    showRedirectPopup() {
      this.submittedRedirectPopupVisible = true;
    },
    hideRedirectPopup() {
      this.submittedRedirectPopupVisible = false;
    },
    jsTransform(opportunity) {
      return new Promise((resolve, reject) => {
        if (
          this?.transitionType !== 1 ||
          !this?.transitionJsFlag ||
          this?.transitionOpportune !== opportunity
        ) {
          resolve();
          return;
        }
        if (this.transitionOpportune === 1) {
          eval(this.transitionCode);
          resolve();
          return;
        }
        if (this.transitionOpportune === 2) {
          apiGetTransitFlag(this.pageId).then((res) => {
            if (res.code === 200 && res.result) {
              // 转换代码
              eval(this.transitionCode);
              resolve();
              return;
            }
          });
        }
        resolve();
        return;
      });
    },
    // // 监听页面滚动
    // handleScroll() {
    //   let scrollTop =
    //     window.pageYOffset ||
    //     document.documentElement.scrollTop ||
    //     document.body.scrollTop;
    //   let total = window.screen.height;
    //   console.log('scroll', scrollTop + total , this.$refs.btn.offsetTop);
    //   if (scrollTop + total > this.$refs.btn.offsetTop + this.$refs.btn.offsetHeight) {
    //     this.fixedBtnShow = true;
    //   } else {
    //     this.fixedBtnShow = false;
    //   }
    // },
    // 根据地区获取接口的地区编号
    getKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value);
    },
    handleChange(e){
      console.log('aaaa',e);
    },
    onConfirm(values) {
      this.cityCode = values[values.length - 1].code;
      console.log('values', values);
      let value = values;
      this.area.receiverProvince = values[0].name;
      this.area.receiverCity = values[1].name;
      this.area.receiverCounty = values[2].name;
      // 获取接口的地区编号
      let code = this.getKeyByValue(this.countyList, this.area.receiverCounty);
      value[2].code = code;
      console.log('value', value);
      this.value = value
        .filter((item) => !!item)
        .map((item) => item.name)
        .join("/");
      this.showArea = false;
    },
    // 获取页面数据
    _getData({ id }) {
      this.$store.commit("setClickId", window.location.href);

      // getLocation().then((res) => {
      //   console.log(res);
      //   //定义获取地址
      //   let pro_list = res;
      //   let cit_list = res.map((item) => item.children);
      //   let cou_list = res
      //     .map((item) => item.children)
      //     .map((item) => item.map((item) => item.children));
      //   //定义地址具体对象
      //   let province_list = {};
      //   let city_list = {};
      //   let county_list = {};
      //   //转换成城市id为键值对名，城市名称为属性
      //   //省份
      //   for (let i = 0; i < pro_list.length; i++) {
      //     province_list[res.map((item) => item.value)[i]] = res.map((item, index) => item.label)[i];
      //   }
      //   //城市
      //   // console.log(city_list.map((item) => (item.map(it => it.value))));
      //   // let arr = city_list.map((item) => (item.map(it => it.label)))
      //   let city_list_value = [];
      //   let city_list_label = [];
      //   for (let i = 0; i < cit_list.map((item) => item.map((it) => it.value)).length; i++) {
      //     for (let j = 0; j < cit_list.map((item) => item.map((it) => it.value))[i].length; j++) {
      //       city_list_value = city_list_value.concat(
      //         cit_list.map((item) => item.map((it) => it.value))[i][j]
      //       );
      //       city_list_label = city_list_label.concat(
      //         cit_list.map((item) => item.map((it) => it.label))[i][j]
      //       );
      //     }
      //   }
      //   for (let i = 0; i < city_list_value.length; i++) {
      //     city_list[city_list_value[i]] = city_list_label[i];
      //   }
      //   //区域
      //   console.log(county_list);
      //   let county_list_value = [];
      //   let county_list_label = [];
      //   for (
      //     let i = 0;
      //     i < cou_list.map((item) => item.map((it) => it.map((i) => i.value))).length;
      //     i++
      //   ) {
      //     for (
      //       let j = 0;
      //       j < cou_list.map((item) => item.map((it) => it.map((i) => i.value)))[i].length;
      //       j++
      //     ) {
      //       county_list_value = county_list_value.concat(
      //         cou_list.map((item) => item.map((it) => it.map((i) => i.value)))[i][j]
      //       );
      //       county_list_label = county_list_label.concat(
      //         cou_list.map((item) => item.map((it) => it.map((i) => i.label)))[i][j]
      //       );
      //     }
      //   }
      //   // console.log(county_list_value);
      //   for (let i = 0; i < county_list_value.length; i++) {
      //     county_list[county_list_value[i]] = county_list_label[i];
      //   }
      //   this.areaList = { province_list, city_list, county_list };
      // });

      let getWebPageApi
      this.componentBoolean? getWebPageApi = getWebPageAllInfo: getWebPageApi = getWebPage
      let params
      this.componentBoolean? params = {
        id,
        productCode: this.productId
      }: params = {
        id,
      }
      this._getAreaList(params);
      getWebPageApi(params).then((res) => {
      // getWebPage({ id }).then((res) => {
        console.log('res===>', JSON.parse(JSON.stringify(res)));
        if (res && res.code === 200) {
          // setTimeout(() => {
          //   let total = window.screen.height;
          //   let btn = this.$refs.btn.offsetTop;
          //   this.fixedLength = this.$refs.wrappers.clientHeight;
          //   if (btn > total) {
          //     this.fixedBtnShow = true;
          //   } else {
          //     this.fixedBtnShow = false;
          //   }
          // }, 200);
          // if (res.result.webPageAllInfoVO.bottomSuction) {
          //   window.addEventListener("scroll", this.handleScroll);
          // }
          if(res.result.webPageAllInfoVO.failJumpTreeFlag === null){
            proxyObj.failJumpTreeFlag = false;
          } else {
            proxyObj.failJumpTreeFlag = res.result.webPageAllInfoVO.failJumpTreeFlag;
          }
          if(obj.failJumpTreeFlag){
            //引入SDK的script
            let scriptElement = document.createElement('script');
            // 如果字段的值等于指定的值，则引入src
            scriptElement.src = 'https://staticcdntx.xwtec.cn/static/taobaoH5/busi/fs/js/sdk/rec_00ad8c2295.min.js';
            document.body.appendChild(scriptElement);
          }
          if(obj.failJumpTreeFlag && !obj.judgmentOrder){
            pushHistory()
          }
          this.payStatus = Number(res.result.webPageAllInfoVO.sellingPrice) > 0? true: false
          this.idNumberPictureFlag = res.result.webPageAllInfoVO.idNumberPictureFlag;
          this.personInfoDisabled = this.idNumberPictureFlag;
          this.identityCardImageTypes = res.result.webPageAllInfoVO.identityCardImageTypes;
          this.submittedRedirectType = res.result.webPageAllInfoVO.submittedRedirectType;
          this.thirdPartyProductType = res.result.webPageAllInfoVO.thirdPartyProductType;
          console.log(
            "🚀 ~ file: index.vue:945 ~ getWebPage ~ this.thirdPartyProductType:",
            this.thirdPartyProductType
          );
          this.submittedRedirectImgUrl = res.result.webPageAllInfoVO.submittedRedirectImgUrl;
          this.fixedBtnShow = res.result.webPageAllInfoVO.bottomSuction;
          this.orderData = res.result.webPageAllInfoVO;
          this.$emit('ok', this.orderData);
          this.collapseForm = res.result.webPageAllInfoVO.collapseForm;
          this.buttonName = res.result.webPageAllInfoVO.buttonName;
          document.title = res.result.webPageAllInfoVO.pageName;
          // const { userIntentionPackageConfigVOS } = res.result;
          // console.log("🚀 ~ file: index.vue:930 ~ getWebPage ~ userIntentionPackageConfigVOS", userIntentionPackageConfigVOS)
          // this.purposePackageColumns = userIntentionPackageConfigVOS.map((item) => ({
          //   text: item.packageName,
          //   key: item.id,
          // }));
          if (res.result.webPageAllInfoVO.rollInfoFlag) {
            // this._getRolling();
            this.listContent = res.result.orderRollList;
          }
          this.transitionType = res.result.webPageAllInfoVO.transitionType;
          this.transitionType === 1 && eval(res.result.webPageAllInfoVO.headCode);
          this.transitionOpportune = res.result.webPageAllInfoVO.transitionOpportune;
          this.transitionJsFlag = res.result.webPageAllInfoVO.transitionJsFlag;
          this.transitionCode = res.result.webPageAllInfoVO.transitionCode;
          this.jsTransform(1);
        } else if (res && res.code === 201 && res.message === "产品已下架") {
          this.$router.push("/soldOut");
        } else {
          this.$router.push("/404");
        }
      });
    },
    // 获取地址列表
    _getAreaList(argument) {
      getArea(argument).then((res) => {
        if (res && res.code === 200) {
          this.countyList = JSON.parse(JSON.stringify(res.result.county_list));
          const { province_list, city_list, county_list } = res.result;
          const province_list_hash_map = {};
          // debugger
          Object.keys(province_list).forEach((code) => {
            province_list_hash_map[code.slice(0, 2)] = {
              value: province_list[code],
              hasChild: false,
            };
          });
          // console.log('123', province_list_hash_map)
          Object.keys(city_list).forEach((code) => {
            if (province_list_hash_map[code.slice(0, 2)]) {
              province_list_hash_map[code.slice(0, 2)].hasChild = true;
            }
          });
          Object.keys(province_list).forEach((code) => {
            if (!province_list_hash_map[code.slice(0, 2)].hasChild) {
              delete province_list[code];
            }
          });
          Object.keys(city_list).forEach((code) => {
            if (!province_list[code.slice(0, 2) + "0000"]) {
              delete city_list[code];
            }
          });
          Object.keys(county_list).forEach((code) => {
            if (!city_list[code.slice(0, 4) + "00"]) {
              delete county_list[code];
            }
          });
          // 重新赋值为六位地区编码
          let counter1 = 2;
          let counter2 = 2;
          for (const key in county_list) {
            // 东莞区下的县级
            if (key.toString().startsWith('441900')) {
              const newKey = 4419 + counter1.toString().padStart(2, '0');
              county_list[newKey] = county_list[key];
              delete county_list[key];
              counter1++;
            }
            // 中山区下的县级
            if (key.toString().startsWith('442000')) {
              const newKey = 4420 + counter2.toString().padStart(2, '0');
              county_list[newKey] = county_list[key];
              delete county_list[key];
              counter2++;
            }
          }
          this.areaList = { province_list, city_list, county_list };
          console.log('this.areaList', this.areaList);
        } else {
          this.$toast(res.message);
        }
      });
    },
    // 获取短信验证码
    _sendSmsCode() {
      if (!this.order.receiverMobile) {
        this.$toast("请输入手机号");
        return;
      } else if(this.order.receiverMobile.length !== 11){
        this.$toast("请输入正确的手机号");
        return
      }
      // getNumberCode({ mobile: this.order.receiverMobile }).then((res) => {
      getUserNumberCode({
        mobile: this.order.receiverMobile,
        productId: this.orderData.productId,
      }).then((res) => {
        this.$toast(res.message);
        this.startTime();
        // this.btnShow = false;
      });
    },
    startTime() {
      this.authTime = 60;
      this.timer = setInterval(() => {
        this.authTime--;
        if (this.authTime <= 0) {
          this.clearTime();
          this.btnShow = true;
        }
      }, 1000);
    },
    clearTime() {
      if (this.timer) {
        this.authTime = "获取验证码";
        clearInterval(this.timer);
      }
    },
    close_Click() {
      this.clearTime();
      this.showPopup = false;
    },
    // 滚动订单信息
    // _getRolling() {
    //   getRolling().then((res) => {
    //     console.log("滚动订单", res);
    //     if (res && res.code === 200) {
    //       // this.listContent.push(res.result[0])
    //       this.listContent = res.result;
    //     }
    //   });
    // },
    goTable() {
      this.fixedBtnShow = false;
      document.documentElement.scrollTop = this.fixedLength;
    },
    goKf() {
      window.location.href = this.orderData.kefuUrl;
    },
    // 协议
    agreementClick() {
      console.log("协议");
      // this.agreementShow = true;
      this.$refs.agreementPopup.show();
    },
    noticeClick() {
      console.log("公告");
      // this.noticeShow = true;
      this.$refs.noticePopup.show();
    },
    policyClick() {
      this.$refs.policyPopup.show();
    },
    letterOfAgreementClick() {
      this.$refs.letterOfAgreementPopup.show();
    },
    personalInformationClick() {
      this.$refs.personalInformationPopup.show();
    },
    // 支付
    // _postPurchase() {
    //   let params = {
    //     deliveryAddress: this.order.address, //收货详细地址
    //     deliveryAreaCode: this.cityCode, //收货地编号
    //     deliveryMobile: this.order.phone, //联系电话
    //     deliveryName: this.order.username, //联系人
    //     pageId: this.$route.params.id, //网页id
    //     verificationCode: this.order.CodeNumber,
    //   };
    //   postBottomPayOrder(params).then((res) => {
    //     if (res && res.code === 200) {
    //       console.log("结果", res);
    //       // if (this.orderData.transformCodeExecuteTiming === 2) {
    //       //   eval(this.orderData.transformCode);
    //       // }
    //       if (res.result.needPay) {
    //         // 跳转支付结果页面
    //         let Url = `${this.$store.state.localhostUrl}/result/${this.$route.params.id}`;
    //         // let redircetUrl = encodeURIComponent(Url)
    //         // window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`
    //         // 跳转订单详情页
    //         // let Url = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`
    //         let redircetUrl = encodeURIComponent(Url);
    //         window.location.href = res.result.payUrl + `&redirect_url=${redircetUrl}`;
    //       } else {
    //         window.location.href = `${this.$store.state.localhostUrl}/Details/${res.result.orderNo}`;
    //       }
    //     } else {
    //       this.$toast(res.message);
    //     }
    //   });
    // },

    // 上传身份证实例
    showIdUploadExample() {
      this.$refs.idCardUploadPopupExample.show();
    },
    // 西藏卡上传身份证实名认证
    showXZCardRealName(orderNo) {
      this.$refs.xzCardRealName.show(orderNo, this.orderData.productId);
    },
    onFrontImgDel(file) {
      console.log(file);
      this.order.idCardFrontUrl = "";
      this.order.receiverName = "";
      this.order.receiverIdCard = "";
      this.personInfoDisabled = true;
    },
    onBackImgDel(file) {
      console.log(file);
      this.order.idCardBackUrl = "";
    },
    onPersonImgDel(file) {
      console.log(file);
      this.order.idCardHandUrl = "";
    },
    // this.order.idCardFrontUrl
    // this.order.idCardBackUrl
    // this.order.idCardHandUrl
    // 上传身份证
    async handleUploadIdCord(file, type) {
      const api = { front: uploadIdCardFront, back: uploadIdCardBack, person: uploadIdCardPerson }[
        type
      ];
      file.status = "uploading";
      file.message = "上传中";
      const formData = new FormData();
      const result = await imgCompressor(file.file);
      console.log(result);
      formData.append("file", result, result.name);
      let res;
      if (type === "person") {
        const params = { frontUrl: this.order.idCardFrontUrl };
        res = await api(formData, params);
      } else {
        res = await api(formData);
      }
      if (res) {
        if (res.code === 200) {
          file.status = "done";
          return Promise.resolve(res);
        }
        file.status = "failed";
        file.message = "识别失败";
        return Promise.reject();
      }
      file.status = "failed";
      file.message = "上传失败";
      return Promise.reject();
    },
    // 上传身份证正面
    uploadBackSide(file) {
      this.handleUploadIdCord(file, "front").then((res) => {
        console.log(res);
        this.order.idCardFrontUrl = res.data.fileUrl;
        this.order.receiverName = res.data.name;
        this.order.receiverIdCard = res.data.idNumber;
        this.personInfoDisabled = false;
      });
    },
    // 上传身份证背面
    uploadReverseSide(file) {
      this.handleUploadIdCord(file, "back").then((res) => {
        console.log(res);
        this.order.idCardBackUrl = res.msg;
      });
    },
    // 上传半身像
    uploadPersonImg(file) {
      this.handleUploadIdCord(file, "person").then((res) => {
        console.log(res);
        this.order.idCardHandUrl = res.msg;
      });
    },
    // 西藏卡相关

    // 上传身份证
    async handleUploadIdCordTibet(file) {
      file.status = "uploading";
      file.message = "上传中";
      const formData = new FormData();
      const result = await imgCompressor(file.file);
      formData.append("file", result, result.name);
      const res = await uploadIdCard(formData);
      if (res) {
        if (res.code === 200) {
          file.status = "done";
          return Promise.resolve(res);
        }
        file.status = "failed";
        file.message = "识别失败";
        return Promise.reject();
      }
      file.status = "failed";
      file.message = "上传失败";
      return Promise.reject();
    },
    // 上传身份证正面
    uploadBackSideTibet(file) {
      this.handleUploadIdCordTibet(file).then((res) => {
        console.log(res);
        this.order.idCardFrontUrl = res.msg;
        this.order.receiverName = res.data.name;
        this.order.receiverIdCard = res.data.idNumber;
        this.personInfoDisabled = false;
      });
    },
    // 上传身份证背面
    uploadReverseSideTibet(file) {
      this.handleUploadIdCordTibet(file).then((res) => {
        console.log(res);
        this.order.idCardBackUrl = res.msg;
      });
    },
    // 上传半身像
    uploadPersonImgTibet(file) {
      this.handleUploadIdCordTibet(file).then((res) => {
        console.log(res);
        this.order.idCardHandUrl = res.msg;
      });
    },
    onFrontImgDelTibet(file) {
      console.log(file);
      this.order.idCardFrontUrl = "";
      this.order.receiverName = "";
      this.order.receiverIdCard = "";
      this.personInfoDisabled = true;
    },
    onBackImgDelTibet(file) {
      console.log(file);
      this.order.idCardBackUrl = "";
    },
    onPersonImgDelTibet(file) {
      console.log(file);
      this.order.idCardHandUrl = "";
    },
  },
};

</script>

<style lang="less" scoped>
.payAlert {
  background-color: #F1F5F8;
  .title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding: 15px 0
  }
  .money{
    font-size: 25px;
    font-weight: bold;
    color: #333;
    .unit {
      font-size: 15px;
      font-weight: bold;
    }
  }
  .alertPayPopup {
    background-color: #fff;
    margin: 15px 10px;
    padding: 15px;
    border-radius: 5px;
    .pay-img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
  .payBtnWrapper {
    background-color: #fff;
    padding: 15px;
    border-radius: 5px 5px 0 0;
    .btn {
      width: 100%;
      background-color: #F05963;
      color: #fff;
      font-weight: bold;
      font-size: 16px;
      border-radius: 10px;
    }
  }
  /deep/ .van-radio__label--left {
    display: flex;
  }
  /deep/ .van-radio {
    display: flex;
    justify-content: space-between;
    margin: 10px 0;
  }
}
.link {
  color: #3d94f6;
  font-weight: normal;
}
.place-an-order {
  min-height: 100%;
  .banner {
    // height 375px
    position: relative;
    .banner-img {
      width: 100%;
      height: 100%;
    }
    .notice {
      position: absolute;
      top: 10%;
      left: 2%;
      width: 45%;
      height: 30px;
      border-radius: 4px;
      background-color: rgba(0, 0, 0, 0.5);
      .notice-swipe {
        height: 30px;
        line-height: 30px;
        color: #fff;
      }
    }
  }
  .order-wrapper {
    background-color: #fff;
    padding: 20px 18px;
    .form-header {
      border-radius: 4px;
      background-color: #fff;
      .form-name {
        padding: 10px 20px;
        font-size: 15px;
        color: #333;
        font-weight: 700;
      }
      .form-sales {
        padding: 10px 16px;
        font-size: 14px;
        color: #b9b5b5;
        font-weight: 500;
      }
      .text-left {
        text-align: center;
        font-size: 20px;
        font-weight: 600;
        height: 50px;
        line-height: 50px;
        padding: 0 10px;
      }
      .text-right {
        text-align: right;
      }
    }
    .order {
      padding: 4px;
      border-radius: 4px;
      background-color: #fff;
      /deep/ .van-cell__title {
        width: 90px;
      }
      /deep/ .van-field__label {
        margin-right: 5px;
      }
      .search-agreement {
        span {
          color: #3d94f6;
        }
      }
      .field-imitate {
        padding: 0 16px;
        .field-label {
          padding: 10px 0;
          font-size: 15px;
          font-weight: bold;
          color: #333;
          text-align: left;
        }
        .field-content {
          display: flex;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(235, 237, 240, 0.6);
          ul {
            display: flex;
            li {
              .title {
                color: #86909c;
                font-size: 14px;
                line-height: 1;
              }
            }
          }
        }
      }
    }
    .bottom-list {
      display: flex;
      justify-content: space-between;
      .pay-number {
        font-size: 13px;
        font-weight: 500;
        color: #333;
      }
    }
  }
  .alertPopup {
    margin: 0 20px;
    padding: 18px;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    .alertText {
      font-size: 16px;
      font-weight: 500;
      padding: 20px 0;
      text-align: left;
    }
    .btn {
      border-radius: 10px;
    }
  }
  .redirect-img {
    margin: 0 20px;
    padding: 8px;
    overflow: hidden;
    box-sizing: border-box;
    border-radius: 8px;
    background-color: #fff;
    display: flex;
    position: relative;
    .redirect-img-close {
      position: absolute;
      padding: 4px;
      top: 12px;
      right: 12px;
      color: #fff;
      background-color: #000;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    img {
      width: 100%;
    }
  }
  .search-check {
    background-color: #fff;
    padding: 15px 10px 15px 18px;
    font-size: 11px;
    color: #666;
    font-weight: 500;
  }
  .btn-wrapper {
    display: flex;
    padding: 2px 10% 15px 10%;
    background-color: #fff;
    .place-btn {
      flex: 1;
      border-radius: 23px;
      background-color: #ec4e2a;
      font-size: 17px;
      font-weight: bold;
      border: none;
    }
  }
  .place-bottom-img {
    width: 100%;
    // height: 100%;
  }
}
::v-deep .van-field__label {
  width: 5em;
}
::v-deep .van-cell__title {
  font-size: 15px;
  font-weight: bold;
  color: #333;
}
::v-deep .van-button--primary {
  background-color: #fff;
  color: #d41010;
  border: 1px solid #ecbcbc;
}
.field_top {
  padding-top: 20px;
}
.show-place-btn {
  width: 100%;
}
.popup_dialog_form {
  margin: 20px 0 20px 0;
}
.pay-wrapper {
  margin-bottom: 20px;
}
.close-img {
  width: 20px;
  height: 20px;
  float: right;
  margin-right: 15px;
  margin-bottom: 10px;
}
:v-deep .van-dialog {
  border-radius: 8px;
}
.bottom-fixed-wrapper {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #eee;
  box-shadow: 0 0 0.05rem 0.05rem #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fixed-btn {
  // position: fixed;
  // bottom: 0;
  // width: 100%;
  // box-shadow: 0 0 0.05rem 0.05rem #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 9999;
  display: flex;
  background-color: #f34538;
  align-items: center;
  .place-btn {
    width: 100%;
    border: none;
    border-radius: 0;
  }
}

.kfWrapper {
  position: fixed;
  right: 3%;
  bottom: 45%;
  width: 60px;
  height: 60px;
  z-index: 100;
  img {
    width: 100%;
    height: 100%;
  }
}
.scrollContainer {
  background-color: red;
  height: 100%; 
  border-radius: 20px; 
  display: flex; 
  color: #fff;
  align-items: center;
  .iconLeft {
    width: 15%;
  }
  .scrollRight {
    width: 80%;
    .content{
      display: flex; 
      justify-content: center;
      align-items: center;
      p:nth-child(2) {
        margin-right: 15px;
      }
    }
  }
}
</style>
